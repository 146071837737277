<template>
  <div class="hear_BD">
    <div class="arrowsBox">
      <div
        class="arrowsItem xiang"
        v-for="(item, index) in listly"
        :key="index"
      >
        <!-- arrows_active  选中背景 arrows_done  已完成背景 arrows_todo 未开始-->
        <div
          class="arrows-up xiang arrows "
          :class="{
            arrows_active: item.prop == num,
            arrows_done: item.prop < num,
            arrows_todo: item.prop > num,
          }"
        >
          <div @click="tiaoz(item)" class="icons jue juzh zi">
            <div>
              <img
                class="iconimg "
                v-if="item.prop == num"
                :src="item.img2"
                alt="1"
                srcset=""
              />
              <img
                class="iconimg "
                v-if="item.prop < num"
                :src="item.img3"
                alt="2"
                srcset=""
              />
              <img
                class="iconimg "
                v-if="item.prop > num"
                :src="item.img1"
                alt="3"
                srcset=""
              />
            </div>
            <div>
              <span class="icontext1 " v-if="item.prop == num">
                {{ item.label }}</span
              >
              <span class="icontext2 " v-if="item.prop < num">
                {{ item.label }}</span
              >
              <span class="icontext3 " v-if="item.prop > num">
                {{ item.label }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BDhear",
  props: {
    //props列表
    num: Number,
  },
  data() {
    return {
      
      IconList: [
        {
          url: "@/assets/BidSecurity/icon.png",
          status: "0",
          name: "选择标段",
        },
        {
          url: "@/assets/BidSecurity/icon1.png",
          status: "1",
          name: "选择出函机构",
        },
        {
          url: "@/assets/BidSecurity/icon2.png",
          status: "0",
          name: "提交资料",
        },
        {
          url: "@/assets/BidSecurity/icon3.png",
          status: "0",
          name: sessionStorage.getItem("bhlein"),
        },
        {
          url: "@/assets/BidSecurity/icon4.png",
          status: "0",
          name: "电子保函协议",
        },
        {
          url: "@/assets/BidSecurity/icon5.png",
          status: "0",
          name: "电子签章",
        },
        {
          url: "@/assets/BidSecurity/icon6.png",
          status: "0",
          name: "支付",
        },
        {
          url: "@/assets/BidSecurity/icon7.png",
          status: "0",
          name: "发放保函",
        },
        {
          url: "@/assets/BidSecurity/9-3.png",
          status: "0",
          name: "快递信息",
        },
      ],
      listly: [
        {
          label: "选择出函机构",
          prop: 1,
          img1: require("@/assets/BidSecurity/2-1.png"),
          img2: require("@/assets/BidSecurity/2-2.png"),
          img3: require("@/assets/BidSecurity/2-3.png"),
          status: "todo",
          url: "/name",
        },
        {
          label: "授信申请",
          prop: 2,
          img1: require("@/assets/xinde/shouxin.png"),
          img2: require("@/assets/xinde/shouxin1.png"),
          img3: require("@/assets/xinde/shouxin1.png"),
          status: "todo",
          url: "/shouxin",
        },
        {
          label: "协议签订",
          prop: 3,
          img1: require("@/assets/xinde/xieyi.png"),
          img2: require("@/assets/xinde/xieyi1.png"),
          img3: require("@/assets/xinde/xieyi1.png"),
          status: "todo",
          url: "/xieyi",
        },
        {
          label: "材料盖章",
          prop: 4,
          img1: require("@/assets/xinde/gaizhang.png"),
          img2: require("@/assets/xinde/gaizhang1.png"),
          img3: require("@/assets/xinde/gaizhang1.png"),
          status: "todo",
          url: "/gaizhang",
        },
        {
          label: "支付",
          prop: 5,
          img1: require("@/assets/BidSecurity/7-1.png"),
          img2: require("@/assets/BidSecurity/7-2.png"),
          img3: require("@/assets/BidSecurity/7-3.png"),
          status: "todo",
          url: "/project",
        },
        {
          label: "电子签约",
          prop: 6,
          img1: require("@/assets/BidSecurity/6-1.png"),
          img2: require("@/assets/BidSecurity/6-2.png"),
          img3: require("@/assets/BidSecurity/6-3.png"),
          status: "todo",
          url: "/added",
        },

        {
          label: "发放保函",
          prop: 7,
          img1: require("@/assets/BidSecurity/8-1.png"),
          img2: require("@/assets/BidSecurity/8-2.png"),
          img3: require("@/assets/BidSecurity/8-3.png"),
          status: "todo",
          url: "/project/defray",
        },
      ],
      listv:[ {
          label: "选择出函机构",
          prop: 1,
          img1: require("@/assets/BidSecurity/2-1.png"),
          img2: require("@/assets/BidSecurity/2-2.png"),
          img3: require("@/assets/BidSecurity/2-3.png"),
          status: "todo",
          url: "/name",
        },
        {
          label: "授信申请",
          prop: 2,
          img1: require("@/assets/xinde/shouxin.png"),
          img2: require("@/assets/xinde/shouxin1.png"),
          img3: require("@/assets/xinde/shouxin1.png"),
          status: "todo",
          url: "/shouxin",
        },
        {
          label: "协议签订",
          prop: 3,
          img1: require("@/assets/xinde/xieyi.png"),
          img2: require("@/assets/xinde/xieyi1.png"),
          img3: require("@/assets/xinde/xieyi1.png"),
          status: "todo",
          url: "/xieyi",
        },
     
        {
          label: "支付",
          prop: 5,
          img1: require("@/assets/BidSecurity/7-1.png"),
          img2: require("@/assets/BidSecurity/7-2.png"),
          img3: require("@/assets/BidSecurity/7-3.png"),
          status: "todo",
          url: "/project",
        },
        {
          label: "电子签约",
          prop: 6,
          img1: require("@/assets/BidSecurity/6-1.png"),
          img2: require("@/assets/BidSecurity/6-2.png"),
          img3: require("@/assets/BidSecurity/6-3.png"),
          status: "todo",
          url: "/added",
        },

        {
          label: "发放保函",
          prop: 7,
          img1: require("@/assets/BidSecurity/8-1.png"),
          img2: require("@/assets/BidSecurity/8-2.png"),
          img3: require("@/assets/BidSecurity/8-3.png"),
          status: "todo",
          url: "/project/defray",
        },],
       list: [
        {
          label: "选择出函机构",
          prop: 1,
          img1: require("@/assets/BidSecurity/2-1.png"),
          img2: require("@/assets/BidSecurity/2-2.png"),
          img3: require("@/assets/BidSecurity/2-3.png"),
          status: "todo",
          url: "/name",
        },
        
        {
          label: "支付",
          prop: 5,
          img1: require("@/assets/BidSecurity/7-1.png"),
          img2: require("@/assets/BidSecurity/7-2.png"),
          img3: require("@/assets/BidSecurity/7-3.png"),
          status: "todo",
          url: "/project",
        },
        {
          label: "电子签约",
          prop: 6,
          img1: require("@/assets/BidSecurity/6-1.png"),
          img2: require("@/assets/BidSecurity/6-2.png"),
          img3: require("@/assets/BidSecurity/6-3.png"),
          status: "todo",
          url: "/added",
        },

        {
          label: "发放保函",
          prop: 7,
          img1: require("@/assets/BidSecurity/8-1.png"),
          img2: require("@/assets/BidSecurity/8-2.png"),
          img3: require("@/assets/BidSecurity/8-3.png"),
          status: "todo",
          url: "/project/defray",
        },
      ],
    };
  },
  created(e) {
    console.log("sessionStorage.getItem",sessionStorage.getItem("unify_code"))
    if (sessionStorage.getItem("xieyi")==1) {
    this.listly= this.list
    }else{
    //     if(sessionStorage.getItem("unify_code")=='91410296MA46T65N4E'){
    //     this.listly= this.listv
 
    
    // }
    }
    // console.log(e); //5
  },
  methods: {
    tiaoz(i) {
      // console.log(i.prop, this.num);
      if (i.prop < this.num) {
        this.$confirm("是否跳转：" + i.label + "", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push(i.url);
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });
      } else {
        // console.log("不可跳转");
      }
    },
    next() {
      for (let item of this.list) {
        if (item.status === "active") {
          item.status = "done";
        }

        if (item.status === "todo") {
          item.status = "active";
          if (this.list[this.list.length - 1].status === "active") {
            this.unFinish = false;
          }
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
.icons {
  display: flex;
  -js-display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* line-height:5rem; */
}
.icontext1 {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.icontext2 {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #1795ff;
}
.icontext3 {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.iconimg {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  /* top: 25px;
  left: 30%; */
}
.hear_BD {
  height: 100%;
  width: 100%;
  height: 80px;
  background-color: #f5f6fa;
  display: flex;
  -js-display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.arrowsBox {
  display: flex;
  -js-display: flex;
  justify-content: center;
  width: 99%;
  font-weight: bold;
}

.arrowsItem {
  width: 25%;
  /* position: relative; */
  height: 80px;
  display: inline-block;
  /* width: 188.78px; */
}
.arrows-up {
  /* width: 25%;
 display: inline-block; */
}
/* .arrows-up {
  transform: skewX(10deg);
}

.arrows-down {
  transform: skewX(-10deg);
} */

/* .arrows {
  height: 50%;

  background: gray;
} */

.arrows-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.arrows_done {
  background: #d5e8fa;
  height: 100%;
  display: flex;
  -js-display: flex;
  justify-content: center;
  flex-direction: column;
}

.arrows_active {
  /* background-url: #1795FF; */
  background: url(../assets/BidSecurity/xin1.png);
  background-repeat: round;
  background-size: cover;
  height: 100%;
  display: flex;
  -js-display: flex;
  justify-content: center;
  flex-direction: column;
}

.arrows_todo {
  background: #fff;
  height: 100%;
  display: flex;
  -js-display: flex;
  justify-content: center;
  flex-direction: column;
}

.arrows_label_done {
  color: #009fe9;
}

.arrows_label_active {
  color: #fff;
}

.arrows_label_todo {
  color: #929393;
}

.btn {
  background: #009fe9;
  color: #fff;
  border: none;
  padding: 6px;
  width: 100px;
  border-radius: 4px;
  margin: auto;
  display: block;
}
</style>
